import Vue from 'vue';

import './sass/main.scss';

import { BootstrapVue } from 'bootstrap-vue';
Vue.use(BootstrapVue);

import RemotePlugin from './plugins/remote.js';
Vue.use(RemotePlugin);

import IdentityPlugin from './plugins/identity.js';
Vue.use(IdentityPlugin);

require('./filters/default.js');

import moment from 'moment';

import router from './router';

/**
 * Validation
 */
import { ValidationProvider, ValidationObserver, extend, localize } from 'vee-validate';
import en from "vee-validate/dist/locale/en.json";
import * as rules from "vee-validate/dist/rules";
Object.keys(rules).forEach(rule => {
	extend(rule, rules[rule]);
});
extend('currency', value => {
	const numeric = /^[0-9|.]+$/;
	if (numeric.test(value)) return true;
	return 'The {_field_} field must be a valid dollar amount';
});
extend('greater_than_or_equal_date', {
	params: ['target'],
	validate(value, { target }) {
		return moment(value).isSameOrAfter(target);
	},
	message: 'To date must be greater than from'
});
extend('future_date', {
	params: ['time'],
	validate(date, { time }) {
		if (moment((date + ' ' + (time ? time : '')).trim()).isSameOrAfter(moment(), 'day')) return true;
		return 'The {_field_} field must be in the future';

	},
	message: 'To date must be greater than from'
});
extend('min_selections', {
	params: ['count'],
	validate(array_values, { count }) {
		console.log(array_values, count, array_values.length >= count);
		if (array_values && array_values.length >= count) return true;
		return 'At least ' + count + ' {_field_} must be selected';

	},
	message: 'To date must be greater than from'
});
localize("en", en);
Vue.component("ValidationObserver", ValidationObserver);
Vue.component('ValidationProvider', ValidationProvider);

import App from './App.vue';

Vue.config.productionTip = true;

new Vue({
	router,
	render: h => h(App),
	data() {
		return {
			sessionTimeout: null
		};
	},
	async beforeMount() {
		const authorization = Vue.identity.getAuthorization();
		if (authorization != null) {
			await Vue.identity.setAuthorization(authorization);
		}
	},
	methods: {
		startSessionTimeout() {
			clearTimeout(this.sessionTimeout);
			this.sessionTimeout = setTimeout(() => {
				this.$children[0].$refs.sessionTimeout.show();
				this.sessionTimeout = setTimeout(() => {
					this.$children[0].logout();
				}, 60000);
			}, 1740000);
		}
	},
	watch: {
		$route(to) {
			if (to.name != 'Login') {
				this.startSessionTimeout();
			}
		}
	}
}).$mount('#app');
