<template>
    <transition name="fade">
        <div v-if="loaded" id="app">
            <b-navbar v-if="$identity.authorization" toggleable="lg" type="dark" variant="brand-dark" class="nav-padding">
                <b-container>
                    <b-navbar-brand href="#">
                        <img src="/logo.png" alt="HCI" class="img-fluid" />
                    </b-navbar-brand>
                    <b-button v-b-toggle.nav-collapse class="d-block d-lg-none"><i class="fas fa-bars"></i></b-button>
                    <b-collapse id="nav-collapse" is-nav>
                        <b-navbar-nav>
                            <b-nav-item exact exact-active-class="active" to="/">Home</b-nav-item>
                            <b-nav-item to="/client/list">Enrollee List</b-nav-item>
                            <b-nav-item to="/client/view">Add New Enrollee</b-nav-item>
                            <b-nav-item-dropdown v-if="user">
                                <template #button-content>
                                    {{user.UserName.toLowerCase()}}
                                </template>
                                <b-dropdown-item to="/profile">Profile</b-dropdown-item>
                                <b-dropdown-item @click="logout">Logout</b-dropdown-item>
                            </b-nav-item-dropdown>
                        </b-navbar-nav>
                        <b-navbar-nav class="ml-auto">
                            <b-nav-form @submit.prevent="search">
                                <b-form-input v-model="searchTerm" size="sm" class="mr-sm-2" placeholder="Search"></b-form-input>
                                <b-button size="sm" class="my-2 my-sm-0" type="submit">Search</b-button>
                            </b-nav-form>
                        </b-navbar-nav>
                    </b-collapse>
                </b-container>
            </b-navbar>
            <b-container class="py-3">
                <router-view :key="key" />
            </b-container>
            <b-modal ref="sessionTimeout" hide-header @cancel="resetSessionTimeout" @ok="logout">
                <b-row>
                    <b-col cols="auto mt-2">
                        <i class="fas fa-exclamation-triangle fa-2x"></i>
                    </b-col>
                    <b-col>
                        Your session is about to expire.<br />
                        You will be logged out in 60 seconds.
                    </b-col>
                </b-row>
                <template #modal-footer="{ ok, cancel }">
                    <b-button size="sm" variant="outline-secondary" @click="ok()">
                        Logout
                    </b-button>
                    <b-button size="sm" variant="primary" @click="cancel()">
                        Continue Session
                    </b-button>
                </template>
            </b-modal>
        </div>
    </transition>
</template>
<script>
    export default {
        data() {
            return {
                loaded: false,
                searchTerm: '',
            };
        },
        async mounted() {
            setTimeout(async () => {
                this.loaded = true;
            }, 500);
        },
        methods: {
            getUrlParameters() {
                let params = window.location.search;
                if (params) {
                    var pa = params.split("?").pop().split("&");
                    var p = {};
                    for (var j = 0; j < pa.length; j++) {
                        var kv = pa[j].split("=");
                        p[kv[0]] = kv[1];
                    }
                    return p;
                } else {
                    return {};
                }
            },
            logout() {
                this.$identity.removeAuthorization();
                window.location.reload();
            },
            resetSessionTimeout() {
                this.$parent.startSessionTimeout();
            },
            search() {
                this.$router.push({ name: 'ClientList', query: { q: this.searchTerm } }).catch(() => { });
            }
        },
        computed: {
            key() {
                return this.$route.fullPath;
            },
            user() {
                return JSON.parse(localStorage.getItem('user'));
            }
        },
    }
</script>
<style lang="scss">
    @import url("https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap");

    :root {
        --font: Roboto, sans-serif;
        --textColor: #374961;
        --linkActiveColor: #41b783;
    }

    html {
        /*margin-left: calc(100vw - 100%);*/
        overflow-y: scroll;
    }

    #app {
        font-family: var(--font);
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: var(--textColor);

        .logo {
            width: 20%;
        }
    }

    .nav {
        /*padding: 30px 0 100px 0;*/

        a {
            font-weight: 500;
            color: var(--textColor);
            margin: 0 5px;
        }

        a.router-link-exact-active {
            color: var(--linkActiveColor);
        }
    }

    .fade-enter-active, .fade-leave-active {
        transition: opacity .5s;
    }

    .fade-enter, .fade-leave-to {
        opacity: 0;
    }

    .clickable {
        cursor: pointer;
    }
</style>
