import moment from 'moment';
export default {
	async install(Vue) {
		const identity = {
			authorization: null,
			user: {},
			async getCurrentUser() {
				let user = localStorage.getItem('user');
				if (user) this.user = JSON.parse(user);
				if (!this.user) {
					this.user = await Vue.remote.request('Identity', 'Get');
				}
				return this.user;
			},
			getAuthorization() {
				const expires = moment.utc(localStorage.getItem('authorization-expires'));
				if (!expires) return;
				if (moment.utc().isAfter(expires)) return;
				return localStorage.getItem('authorization');
			},
			async setAuthorization(authorization) {
				localStorage.setItem('authorization', authorization);
				localStorage.setItem('authorization-expires', moment.utc().add(15, 'minutes').format());
				this.authorization = authorization;
				Vue.remote.setAuthorization(authorization);
				const response = await Vue.remote.request('Identity', 'Get');
				if (response && !response.error) {
					this.user = response;
					localStorage.setItem('user', JSON.stringify(this.user));
					return true;
				}
				return false;
			},
			removeAuthorization() {
				localStorage.clear();
				this.authorization = null;
            }
		};
		Vue.prototype.$identity = identity;
		Vue.identity = identity;
	}
}