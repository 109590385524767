import axios from 'axios';
export default {
	async install(Vue) {
		const baseURL = window.location.host.indexOf('10.7.7.18') == -1 ? 'https://portal.homeconfinementinc.com/CaseManagementPortal/Remote' : 'http://hci.directionaldevelopment.com/CaseManagementPortal/Remote';
		axios.interceptors.request.use(async function (config) {
			// config.baseURL = 'https://portal.homeconfinementinc.com/CaseManagementPortal/Remote';
			// config.baseUrl = 'http://hci.directionaldevelopment.com/CaseManagementPortal/Remote';
			// config.baseURL = 'https://10.7.7.18:44360/CaseManagementPortal/Remote';
			config.baseURL = baseURL;
			return config;
		});
		axios.interceptors.response.use(response => {
			return response;
		}, error => {
			if (error.response.status === 401) {
				Vue.identity.removeAuthorization();
				window.location = "/";
			}
			return error;
		});
		const remote = {
			authorization: null,
			async request(service, method, params) {
				const response = await axios.post(service + '.asmx/' + method, params ? params : {}, {
					'Accept': 'application/json',
					'Content-Type': 'application/json; charset=utf-8',
				});
				return response.data ? response.data.d : null;
			},
			clientDocument(id) {
				window.location = baseURL + '/ClientDocument.ashx?id=' + id + '&auth=' + this.authorization;
			},
			download(filename, service, method, params) {
				axios({
					url: service + '.asmx/' + method,
					method: 'POST',
					data: params ? params : {},
					responseType: 'blob',
				}).then((response) => {
					const url = window.URL.createObjectURL(new Blob([response.data]));
					const link = document.createElement('a');
					link.href = url;
					link.setAttribute('download', filename);
					document.body.appendChild(link);
					link.click();
				});
            },
			async setAuthorization(authorization) {
				this.authorization = authorization;
				await axios.interceptors.request.use(async function (config) {
					config.headers.common['Authorization'] = authorization;
					return config;
				});
			}
		};
		Vue.prototype.$remote = remote;
		Vue.remote = remote;
	}
}