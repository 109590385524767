import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
	{
		path: "/client/view/:id?",
		name: "ClientView",
		component: () => import(/* webpackChunkName: "client-view" */ "../components/ClientView.vue"),
		meta: {
			requiresAuth: true,
		},
		props: true
	},
	{
		path: "/client/list",
		name: "ClientList",
		component: () => import(/* webpackChunkName: "client-list" */ "../components/ClientList.vue"),
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: "/forgot-password",
		name: "ForgotPassword",
		component: () => import(/* webpackChunkName: "forgot-password" */ "../components/ForgotPassword.vue"),
		meta: {
			requiresAuth: false,
		},
	},
	{
		path: "/",
		name: "Home",
		component: () => import(/* webpackChunkName: "home" */ "../components/Home.vue"),
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: "/login",
		name: "Login",
		component: () => import(/* webpackChunkName: "login" */ "../components/Login.vue"),
		meta: {
			requiresAuth: false,
		},
	},
	{
		path: "/profile",
		name: "Profile",
		component: () => import(/* webpackChunkName: "profile" */ "../components/Profile.vue"),
		meta: {
			requiresAuth: true,
		},
	},
];

const router = new VueRouter({
	mode: "history",
	routes,
});

router.beforeEach(async (to, from, next) => {
	if (to.matched.some(record => record.meta.requiresAuth)) {
		const authorization = localStorage.getItem('authorization');
		if (authorization == null) {
			next({
				path: '/login',
				params: { nextUrl: to.fullPath }
			});
		} else {
			next();
		}
	} else {
		next();
	}
});

export default router;
