import Vue from 'vue';
import moment from 'moment';

Vue.filter('commify', function (value) {
	return value ? value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '';
});

Vue.filter('currency', function (value) {
	var formatter = new Intl.NumberFormat('en-US', {
		style: 'currency',
		currency: 'USD',
	});
	return formatter.format(value);
});

Vue.filter('email', function (value) {
	return value ? value.toLowerCase() : '';
});

Vue.filter('spanFromNow', function (value) {
	let span = moment.duration(moment().diff(value)).humanize();
	if (moment(value).isBefore(moment())) {
		return span + ' ago';
	} else {
		return span + ' from now';
	}
});

Vue.filter('longDateTime', function (value) {
	let m = moment(value);
	if (m.isValid()) return m.format("dddd, MMMM Do YYYY, h:mma");
	return '';
});

Vue.filter('longDate', function (value) {
	let m = moment(value);
	if (m.isValid()) return m.format("MM/DD/YYYY");
	return '';
});

Vue.filter('monthDay', function (value) {
	let m = moment(value);
	if (m.isValid()) return m.format("MMM Do");
	return '';
});

Vue.filter('monthYear', function (value) {
	let m = moment(value);
	if (m.isValid()) return m.format("MMM YYYY");
	return '';
});

Vue.filter('nl2br', function (value) {
	return (value + '').replace(/(\r\n|\n\r|\r|\n)/g, '<br>' + '$1');
});

Vue.filter('ordinal', function (value) {
	var j = value % 10,
		k = value % 100;
	if (j == 1 && k != 11) {
		return value + "st";
	}
	if (j == 2 && k != 12) {
		return value + "nd";
	}
	if (j == 3 && k != 13) {
		return value + "rd";
	}
	return value + "th";
});

Vue.filter('phone', function (value) {
	return value ? value.replace(/(\d\d\d)(\d\d\d)(\d\d\d\d)/, '($1) $2-$3') : '';
});

Vue.filter('shortDate', function (value) {
	let m = moment(value);
	if (m.isValid()) return m.format("MM/DD/YY");
	return '';
});

Vue.filter('shortTime', function (value) {
	let m = moment(value);
	if (m.isValid()) return m.format("h:mma");
	return '';
});

Vue.filter('year', function (value) {
	let m = moment(value);
	if (m.isValid()) return m.format("YYYY");
	return '';
});